<template>
    <div class="srmtemplate">
        <myhead :param="param" />
        <section>
            <div class="bannersrm">
                <div style="height:280px; "></div>
                <div style="font-size: 44px;font-weight: bold;color: #ffffff;"></div>

            </div>
        </section>
        <section class="body1200" style="margin-top: 90px;">
            <div class="txt18">能源产品应用方案</div>
            <div class="txt60" style="margin-top: 30px">光伏阳光房</div>
            <div class="txt18" style="margin-top: 60px;width:860px;">
                与普通阳光房相比，一般光伏阳光房使用光伏双玻组件代替建造屋顶所用的玻璃或木质结构，环保美观；铺设的光伏板能够起到很好的遮阳隔热作用；光伏发电可供自家使用，余电上网能够创造收益，一举多得。阳光房具备透光性的特点，在组件选择上一般会选择双玻透光组件进行安装。双玻组件即双面玻璃晶体硅太阳电池组件，作为一种新型建筑材料，它有着美观、透光可控、节能发电的优点。

            </div>
        </section>
        <section class="body1200" style="margin-top: 150px;">
            <div  class="txt42">
                应用场景
            </div>

            <div class="txt18" style="margin-top: 30px;width:860px;">
                辉腾光伏能源通过将信息技术、互联网技术与光伏技术跨界融合，集成高效光伏发电，高压储能，实现可视化家庭能源管理，场景化、简单化一键用电，为客户带来更安全、多发电、好管理的美好生活体验。
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell"><div class="srm_cj srm_cj1"> </div></div>
                <div class="hcell"><div class="srm_cj srm_cj2"> </div></div>
                <div class="hcell"><div class="srm_cj srm_cj3"> </div></div>
            </div>
        </section>

        <section class="body1200" style="margin-top: 150px;">
            <div style="font-size: 42px;">
                方案优势
            </div>
            <div class="txt18" style="width: 860px;margin-top: 60px;">

                辉腾光伏能源通过将信息技术、互联网技术与光伏技术跨界融合，集成高效光伏发电，高压储能，实现可视化家庭能源管理，场景化、简单化一键用电，为客户带来更安全、多发电、好管理的美好生活体验。

            </div>
            <div style="margin-top: 56px;">
                <img :src="srm_ys">
            </div>
        </section>

        <section class="body1200" style="margin-top: 150px;">
            <div style="font-size: 42px;">
                相关案例
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell"><div class="hec_al hec_al1"><div class="hec_albg1" ><div class="altxt1">
                    <div><img :src="address"></div>
                    <div>四川阿坝1MW扶贫光伏项目</div>
                </div> </div></div></div>
                <div class="hcell"><div class="hec_al hec_al2"><div class="hec_albg2" ><div class="altxt2">
                    <div><img :src="address"></div>
                    <div>四川理塘6MW扶贫光伏项目</div>
                </div> </div></div></div>
                <div class="hcell"><div class="hec_al hec_al3"> <div class="hec_albg3" ><div class="altxt3">
                    <div><img :src="address"></div>
                    <div>重庆巫溪整县扶贫光伏项目</div>
                </div> </div></div></div>
            </div>
        </section>

        <section style="height: 90px;"></section>
    </div>
</template>

<script>
    import myhead  from  '../components/myhead.vue'
    import cpblock_two  from  '../components/cpblock_two.vue'
    import cpblock_one  from  '../components/cpblock_one.vue'
    const param = {
        pageindex: 1,
        rows: 6,
        page: 1,
    }
    export default {
        name: "gflamp" ,
        components: {
            myhead,
            cpblock_two,
            cpblock_one
        },
        data() {
            return {
                param,
                factorygh:1,
                srm_ys: require('../assets/srm_ys.png'),
                address: require('../assets/address.png'),
            }
        },
        mounted() {

        },
        methods: {

        },
    }
</script>

<style scoped>
    .srmtemplate{
        color: #202020;
        text-align: left;

    }
    .ghm{
        width: 152px;
        text-align: center;
    }
    .txtH16{
        font-size: 16px;
        color:rgba(20,20,20,0.8);
        line-height: 80px;
    }
    .txt18{
        font-size: 18px;
        color:rgba(20,20,20,0.8);
        line-height: 32px;
    }
    .txt42{
        font-size: 42px;
        color:rgba(20,20,20,1);
        line-height: 42px;
    }
    .txt60{
        font-size: 60px;
        color:rgba(20,20,20,1);
        line-height: 60px;
    }
    .bannersrm{
        background-image: url('~assets/srm_banner.jpg');
        height: 600px;
    }



    .hec_al{
        width: 374px;
        height: 270px;
        border-radius: 10px;

    }

    .hec_al1{
        background-image: url('~assets/hec_al1.jpg');
    }
    .hec_al2{
        background-image: url('~assets/hec_al2.jpg');
    }
    .hec_al3{
        background-image: url('~assets/hec_al3.jpg');
    }

    .srm_cj{
        width: 374px;
        height: 270px;
        border-radius: 10px;
    }
    .srm_cj1{
        background-image: url('~assets/srm_cj1.jpg');
    }
    .srm_cj2{
        background-image: url('~assets/srm_cj2.jpg');
    }
    .srm_cj3{
        background-image: url('~assets/srm_cj3.jpg');
    }


    .altxt1{
        color: #ffffff;
        font-size: 18px;
        text-align: center;
        display: none;
    }
    .hec_albg1{
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items:center;
        display: flex;
    }

    .altxt2{
        color: #ffffff;
        font-size: 18px;
        text-align: center;
        display: none;
    }
    .hec_albg2{
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items:center;
        display: flex;
    }

    .altxt3{
        color: #ffffff;
        font-size: 18px;
        text-align: center;
        display: none;
    }
    .hec_albg3{
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items:center;
        display: flex;
    }

    .hec_al1:hover  .hec_albg1 {
        background-color: rgba(255,255,255,0.4)
    }
    .hec_al1:hover .altxt1{
        display: inline;
    }

    .hec_al2:hover  .hec_albg2 {
        background-color: rgba(255,255,255,0.4)
    }
    .hec_al2:hover .altxt2{
        display: inline;
    }
    .hec_al3:hover  .hec_albg3 {
        background-color: rgba(255,255,255,0.4)
    }
    .hec_al3:hover .altxt3{
        display: inline;
    }

</style>
